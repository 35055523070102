let env = 'aim-qida' // 发布环境 [m2-qida, m1, m2]
class ApiConfig {
    /**
     * @param {string} apiName api名称
     */
    constructor(apiName) {
        this.resStatus = -1
        this.name = apiName
        this.baseURL = ''

        // 此函数只用于PUT, POST, PATCH方法请求
        // 按数组顺序执行函数,返回值作为下一个函数的输入值
        // 最后一个函数必须返回值的类型是:[string, ArrayBuffer, Stream]
        this.transformRequest = [
            data => {
                // 请求之前对参数再一次封装
                return window.JSON.stringify(data)
            }
        ]

        // 处理接口返回数据在then/catch之前执行动作
        // 参数 data[string]类型
        this.transformResponse = [
            data => {
                // 返回出去之前必须是json对象
                if (data) {
                    // 身份验证
                    if (this.resStatus === 401) {
                        ApiConfig.checkLogin(data)
                        return
                    }
                    if (this.resStatus === 400) {
                        let errorKey = JSON.parse(data).error.key
                        if (errorKey === 'sentinel.tigger') {
                            window.location.replace(JSON.parse(data).error.url)
                            return false
                        }
                        if (errorKey === 'api.cela.ccb.phone.from.empid.error') {
                            this.$message.error('抱歉，该课程已下架！')
                            return false
                        }
                    }
                    if (this.resStatus === 403) {
                        // 无权限
                        let host = window.location.host
                        window.location.replace(`https://${host}/#/nopermission`)
                        return false
                    }
                    if (this.resStatus === 500) {
                        let errorKey = window.JSON.parse(data).error.key
                        if (errorKey === 'global.ServiceInternalError') {
                            return false
                        }
                    }
                    if (this.resStatus === 502) {
                        return false
                    }
                    try {
                        data = window.JSON.parse(data)
                    } catch (e) {
                        console.warn(e)
                    }
                }
                return data
            }
        ]

        // 设置resolve和reject判定标准
        this.validateStatus = status => {
            this.resStatus = status
            return status >= 200 && status < 300 // default
        }

        this.headers = {
            source: window.source,
            'Content-Type': 'application/json;charset=UTF-8'
        }
        // if (apiName === 'log') {
        //   this.headers = {
        //     'source': window.source,
        //     'Content-Type': 'application/json;charset=UTF-8',
        //     'type': 2,
        //     'timestamp': new Date().getTime()
        //   }
        // }
        // log2 为页面加载日志  log 为行为日志
        if (apiName === 'log2' || apiName === 'log') {
            this.headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                timestamp: new Date().getTime()
            }
        }
        if (apiName === 'log2') {
            this.headers['type'] = 2
        } else if (apiName === 'log') {
            this.headers['type'] = 1
        }
        // 请求地址参数末尾添加随机数
        this.params = {
            random: Math.random()
        }
    }

    setBaseURL(url) {
        this.baseURL = url[env]
    }
}

ApiConfig.checkLogin = function(data) {
    if (data) {
        data = window.JSON.parse(data)
        if (
            data.key === 'global.token.invalid' ||
            (data.error !== null && (data.error.key === 'global.token.invalid' || data.error.key === 'global.token.empty' || data.error.key === 'global.user.kicked.out'))
        ) {
            try {
                window.clearlocalStorageForKey()
            } catch (e) {
                window.clearCookieForKey()
            }
            if (window.isApp) {
                window.yxt.biz.user.logout({
                    param: {
                        cause: 'tokenexpired'
                    }
                })
            } else {
                if (!window.getLocalStorage('returnUrl')) {
                    // 如果存在return url，则不赋值，因为掉接口时是异步操作，可能会写登录界面的地址
                    window.setLocalStorage('returnUrl', window.location.href)
                }
                location.href = window.goMainStationUrl()
                // window.location.hash = window.goMainStationUrl()
            }
        }
    }
}

class Aim extends ApiConfig {
    constructor(name) {
        super(name)
        // 企大api配置
        this.setBaseURL({
            // 'm2-qida': process.env.VUE_APP_NODE_ENV === 'development' ? 'https://api1.u.ccb.com/v1/' : 'https://api-cu.yunxuetang.com.cn/v1/'
            'aim-qida': `http://${window.location.host}/v1`
            // 'aim-qida': `http://zhenrener.com/v1`
        })
    }
}

const eduapiconfig = new Aim('aim')
// 输出qidaApiConfig
export { eduapiconfig }
