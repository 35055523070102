/**
 * @desc: ajax请求
 * @author: caoqian
 * @date: 20201015
 */

import ajax from './ajax'
import axios from 'axios'
import { params } from '@/utils/index'
import { eduapiconfig } from '../config/apiconfig'

//我的考试列表
export const examList = data => ajax.get(`/v1/exam/list${params(data)}`)

// 党建预览
export const preview = arrangeId => ajax.get(`/v1/exam/${arrangeId}/preview`)
//考题
export const questions = (arrangeId, userExamMapId) => ajax.get(`/v1/exam/${arrangeId}/userexammap/${userExamMapId}/start`)

//单题提交
export const examAnswer = (userExamId, arrangeId, userExamMapId, data) => ajax.post(`/v1/exam/userexam/${userExamId}/answer?arrangeId=${arrangeId}&userExamMapId=${userExamMapId}`, data)

// 提交试卷
export const examSubmit = (userExamId, arrangeId, userExamMapId, data) => ajax.post(`/v1/exam/userexam/${userExamId}/submit?arrangeId=${arrangeId}&userExamMapId=${userExamMapId}`, data)

//考试结果
export const examResult = (userExamId, arrangeId, userExamMapId, isAdminSearch) =>
    ajax.get(`/ote/web/userexam/${userExamId}/result?arrangeId=${arrangeId}&userExamMapId=${userExamMapId}&isAdminSearch=${isAdminSearch}`)

//参入过的考试结果列表
export const submittedList = (arrangeId, userExamMapId) => ajax.get(`/v1/exam/${arrangeId}/submitted/userexam?userExamMapId=${userExamMapId}`)

//考试统计
export const examStatistics = (userExamId, arrangeId) => ajax.get(`/v1/exam/userexam/${userExamId}/statistics?arrangeId=${arrangeId}`)
//答案解析
export const examAnswerList = (userExamId, arrangeId) => ajax.get(`/v1/exam/userexam/${userExamId}/result?arrangeId=${arrangeId}`)

//每两分钟提交一次,更新用户考试时间
export const examUpdateTime = (userExamId, arrangeId, userExamMapId, data) => ajax.post(`/v1/exam/userexam/${userExamId}/time?arrangeId=${arrangeId}&userExamMapId=${userExamMapId}`, data)

//投产提示
export const checkmanage = data => ajax.post(`/v1/exam/checkmanage?type=start`, data)

//我的练习列表
export const practiceList = data => ajax.post(`/ote/practiceExam/user/queryMyPracticeList`, data)
//存练习记录
export const answerPracticeExam = data => ajax.post(`/ote/practiceExam/user/answerPracticeExam`, data)
// 练习题列表
export const practiceExamList = id => ajax.get(`/ote/practiceExam/user/start/${id}`)
// 扫码判断权限
export const getAuth = id => ajax.get(`/ote/auth/scanCodeCheckResult/${id}`)

//分数排名
export const examScoreRank = () => ajax.get(`/exam/exemrank`)

export const addExamInfo = data => ajax.post(`/exam/addExamInfo`, data)
